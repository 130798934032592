import React, { useEffect, useRef } from "react";
import "./style.css";

const HeroSection = ({ text, img, sectionClass }) => {
  const typedTextRef = useRef(null);

  useEffect(() => {
    const typedText = typedTextRef.current;
    const originalText = text;
    let currentIndex = 0;
    let isBackspacing = false;

    const interval = setInterval(() => {
      if (!isBackspacing) {
        if (currentIndex <= originalText.length) {
          typedText.innerHTML = originalText.slice(0, currentIndex);
          currentIndex++;
        } else {
          isBackspacing = true;
          currentIndex--;
        }
      } else {
        if (currentIndex >= 0) {
          typedText.innerHTML = originalText.slice(0, currentIndex);
          currentIndex--;
        } else {
          clearInterval(interval);
        }
      }
    }, 100); // Adjust typing speed here

    return () => clearInterval(interval);
  }, [text]);

  return (
    <section className={`${sectionClass} sectionContainer`}>
      <div className="c-hero__backgroundOverlay"></div>
      <div className="c-hero__container responsivePadding">
        <div className="c-hero__content">
          <h1 id="typedText" style={{ textAlign: "left" }}>
            <strong>make</strong>
            <span id="type" ref={typedTextRef}></span>
            <span className="Typewriter__cursor">|</span>
          </h1>
        </div>
        <img id="img" src={img} alt="" className="c-hero__img" />
      </div>
    </section>
  );
};

export default HeroSection;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter, Routes, and Route
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import Footer from "./Components/Footer";
import NewNavbar from "./Components/Navbar/NewNavbar";
import About from "./Components/About";
import ContactForm from "./Components/contact";
import Course from "./Components/Course";
import Tutors from "./Components/Tutors";

function App() {
  return (
    <Router>
      <div className="App">
        <NewNavbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/About" element={<About />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/subjects" element={<Course />} />
          <Route path="/tutors" element={<Tutors />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

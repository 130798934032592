import React, { useState } from "react";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import "./style.css";
import { Link, Route, useNavigate } from "react-router-dom";

const NewNavbar = () => {
  const [hang, setHang] = useState(false);
  const history = useNavigate();
  const hangBurger = () => {
    setHang(!hang);
  };
  return (
    <div>
      <header class="c-nav sectionContainer bgBlueDark">
        <div
          style={{
            display: "flex",
            width: 230,
            justifyContent: "space-between",
            padding: 13,
            // background: "#ffd787d1",
            borderRadius: 12,
          }}
        >
          {/* <a href="">
            <img src={logo} alt="" class="c-nav__logo" />
          </a> */}
          <div>
            <a href="">
              <img src={logo} alt="" class="c-nav__logo" />
            </a>
          </div>
          <div style={{ marginLeft: 5, cursor: "pointer" }}>
            <div
              style={{
                fontFamily: "montserrat",
                fontSize: 25,
                fontWeight: 700,
                letterSpacing: 2,
                color: "rgb(136 112 49)",
              }}
            >
              Learnogic
            </div>
            <div
              style={{
                fontFamily: "montserrat",
                fontSize: 12,
                fontWeight: 600,
                letterSpacing: 2,
                color: "#fff  ",
              }}
            >
              Raise Your Level
            </div>
          </div>
        </div>{" "}
        <div class="c-nav__menu">
          <div class="c-nav__links">
            <div
              onClick={() => {
                history("/");
              }}
              class="c-nav__link"
            >
              Home
            </div>
            <div
              onClick={() => {
                history("/subjects");
              }}
              class="c-nav__link"
            >
              Subjects
            </div>
            <Link to="/tutors" class="c-nav__link">
              Tutors
            </Link>
            <Link to="/about" class="c-nav__link">
              About
            </Link>
            <Link to="/contact" class="c-nav__link">
              Contact
            </Link>
          </div>
          <div class="c-nav__buttons">
            <a
              type="button"
              class="c-button c-button--whiteOutline c-button--span"
              style={{}}
              href="https://demo.nexonetics.com/wp-login.php"
            >
              Login
            </a>

            <a
              type="button"
              class="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "white",
                textDecoration: "none",
              }}
              href="https://demo.nexonetics.com/"
            >
              Get started today
            </a>
          </div>
        </div>
        <div class="c-nav__hamburger" onClick={hangBurger}>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/hamburgerClosed.svg"
            alt=""
            class="c-nav__hamburgerImg"
          />
        </div>
        {hang && (
          <div class="c-nav__menu--mobile bgBlueDark">
            <div class="c-nav__links--mobile">
              <Link to="/" class="c-nav__link--mobile">
                Home
              </Link>
              <Link to="/subjects" class="c-nav__link--mobile">
                Subjects
              </Link>
              <Link to="/tutors" class="c-nav__link--mobile">
                Subjects
              </Link>
              <Link to="/about" class="c-nav__link--mobile">
                About
              </Link>
              <Link to="/contact" class="c-nav__link--mobile">
                Contact
              </Link>
            </div>
            <div class="c-nav__buttons--mobile">
              <a
                type="button"
                class="c-button1 c-button--whiteOutline c-button--span"
                href="https://demo.nexonetics.com/wp-login.php"
              >
                Login
              </a>
              <a
                type="button"
                class="c-button1 ctaOrange c-button--span"
                style={{
                  background: "rgb(66 66 66)",
                  border: "none",
                  textDecoration: "none",
                }}
                href="https://demo.nexonetics.com/"
              >
                Get started today
              </a>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default NewNavbar;

import React from "react";
import "./style.css";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
    reset(); // Optionally reset the form after submission
  };

  return (
    <div>
      <div className="headerSpacing"></div>
      <section className="sectionContainer lgDark">
        <div className="c-contact responsivePadding">
          <div className="c-contact__content">
            <h1 className="c-contact__contentTitle">Contact form</h1>
            <p className="c-contact__contentIntro">
              If you would like to learn more about
            </p>
            <ul className="c-contact__list">
              <li>Membership</li>
              <li>School Subscription</li>
              <li>Purchasing Resources</li>
              <li>Working with Us (Careers)</li>
            </ul>
            <p className="c-contact__followUp">
              Get in touch and we will connect with you soon.
            </p>
            <div className="c-contact__infos">
              <div className="c-contact__info">
                <i className="fa-solid fa-address-book c-contact__infoIcon"></i>
                <div className="c-contact__infoContent">
                  <div className="c-contact__infoContentTitle">
                    Contact us by email
                  </div>
                  <div className="c-contact__infoContentText">
                    learning@dilepex.com
                  </div>
                </div>
              </div>
              <div className="c-contact__info">
                <i className="fa-solid fa-phone c-contact__infoIcon"></i>
                <div className="c-contact__infoContent">
                  <div className="c-contact__infoContentTitle">
                    Contact us by phone
                  </div>
                  <div className="c-contact__infoContentText">+44 7487220123</div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-contact__form">
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-div">
                <div className="form-text-div">
                  <div className="Labelcontent">
                    School or Institution Name
                    <span style={{ fontWeight: "300", color: "red", fontSize: 13, fontStyle: "italic" }}>
                      (Required)
                    </span>
                  </div>
                  <input
                    placeholder="School or Institution Name"
                    className="inputContent"
                    {...register("school_name", { required: true })}
                  />
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Name
                    <span style={{ fontWeight: "300", color: "red", fontSize: 13, fontStyle: "italic" }}>
                      (Required)
                    </span>
                  </div>
                  <input
                    placeholder="Name"
                    className="inputContent"
                    {...register("name", { required: true })}
                  />
                </div>
              </div>
              <div className="form-div">
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Email Address
                    <span style={{ fontWeight: "300", color: "red", fontSize: 13, fontStyle: "italic" }}>
                      (Required)
                    </span>
                  </div>
                  <input
                    placeholder="Email Address"
                    className="inputContent"
                    {...register("email_address", { required: true })}
                  />
                  {errors.email_address && <span className="error-message">This field is required</span>}
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Position
                    <span style={{ fontWeight: "300", color: "red", fontSize: 13, fontStyle: "italic" }}>
                      (Required)
                    </span>
                  </div>
                  <select
                    className="inputContent"
                    {...register("position", { required: true })}
                  >
                    <option value="">Select a position</option>
                    <option value="Student">Student</option>
                    <option value="Teacher">Teacher</option>
                    <option value="Examiner">Examiner</option>
                  </select>
                  {errors.position && <span className="error-message">This field is required</span>}
                </div>
              </div>
              <div className="form-text-div">
                <div className="Labelcontent">Your message</div>
                <input
                  placeholder="Please enter your message"
                  className="inputContent"
                  style={{ width: "100%", height: 120 }}
                  {...register("description")}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <button type="submit" className="submitButton">Submit</button>
              </div>
            </form>
          </div>
          <div className="c-contact__infoMessage">
            <p>All these fields will be required for them to fill. They cannot submit without filling in all the information.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;

import React, { useState } from "react";
import "./style.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Box = () => {
  const isMobile = window.innerWidth <= 1000;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handlePrevClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };
  return (
    <>
      {isMobile ? (
        <section class="sectionContainer">
          <div
            class="responsivePadding"
            style={{ maxWidth: "1310px", width: "100%", paddingRight: 0 }}
          >
            <div class="c-carousel swiper swiper-initialized swiper-horizontal swiper-android swiper-backface-hidden">
              <div class="c-carousel__header">
                <h2 class="c-carousel__headerHeading">Who we empowered</h2>
                <div class="c-carousel__navigation c-carousel__navigation--desktop">
                  <i
                    className="fa-solid fa-angle-left c-carousel__navigationButton c-carousel__navigationButton--left"
                    tabIndex="-1"
                    role="button"
                    aria-label="Previous slide"
                    onClick={handlePrevClick}
                  ></i>
                  <i
                    className="fa-solid fa-angle-right c-carousel__navigationButton c-carousel__navigationButton--right"
                    tabIndex="-1"
                    role="button"
                    aria-label="Next slide"
                    onClick={handleNextClick}
                  ></i>
                </div>
              </div>
              <Carousel
                class="c-carousel__testimonials swiper-wrapper"
                autoPlay
                selectedItem={selectedIndex}
                infiniteLoop
                onChange={(index) => setSelectedIndex(index)}
              >
                <div
                  class="c-carousel__testimonial swiper-slide swiper-slide-active"
                  style={{ width: "90%", marginRight: "30px" }}
                >
                  <div class="c-carousel__testimonialTitle">
                    Improved access for all students{" "}
                  </div>
                  <div class="c-carousel__testimonialMessage">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut mauris ligula, convallis quis est a, lobortis
                      pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                      pretium, orci felis rutrum enim, nec placerat metus odio
                      sit amet velit. Integer ac porttitor tortor. Donec
                      sollicitudin, nisi a rhoncus consectetur, tortor est
                      efficitur nibh, volutpat.
                    </p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <button class="c-carousel__testimonialButton">
                      Read more
                    </button>
                  </div>
                  <div class="c-carousel__testimonialAuthor">
                    Head of English, Greenwich International School{" "}
                  </div>
                </div>
                <div
                  class="c-carousel__testimonial swiper-slide swiper-slide-next"
                  style={{ width: "90%", marginRight: "30px" }}
                >
                  <div class="c-carousel__testimonialTitle">
                    Improved access for all students 3{" "}
                  </div>
                  <div class="c-carousel__testimonialMessage">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut mauris ligula, convallis quis est a, lobortis
                      pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                      pretium, orci felis rutrum enim, nec placerat metus odio
                      sit amet velit. Integer ac porttitor tortor. Donec
                      sollicitudin, nisi a rhoncus consectetur, tortor est
                      efficitur nibh, volutpat.
                    </p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <button class="c-carousel__testimonialButton">
                      Read more
                    </button>
                  </div>
                  <div class="c-carousel__testimonialAuthor">
                    Head of English, Greenwich International School{" "}
                  </div>
                </div>
                <div
                  class="c-carousel__testimonial swiper-slide"
                  style={{ width: "90%", marginRight: "30px" }}
                >
                  <div class="c-carousel__testimonialTitle">
                    Improved access for all students 2{" "}
                  </div>
                  <div class="c-carousel__testimonialMessage">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut mauris ligula, convallis quis est a, lobortis
                      pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                      pretium, orci felis rutrum enim, nec placerat metus odio
                      sit amet velit. Integer ac porttitor tortor. Donec
                      sollicitudin, nisi a rhoncus consectetur, tortor est
                      efficitur nibh, volutpat.
                    </p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <button class="c-carousel__testimonialButton">
                      Read more
                    </button>
                  </div>
                  <div class="c-carousel__testimonialAuthor">
                    Head of English, Greenwich International School{" "}
                  </div>
                </div>
              </Carousel>
              {/* <div class="c-carousel__navigation c-carousel__navigation--desktop">
                <i
                  className="fa-solid fa-angle-left c-carousel__navigationButton c-carousel__navigationButton--left"
                  tabIndex="-1"
                  role="button"
                  aria-label="Previous slide"
                  onClick={handlePrevClick}
                ></i>
                <i
                  className="fa-solid fa-angle-right c-carousel__navigationButton c-carousel__navigationButton--right"
                  tabIndex="-1"
                  role="button"
                  aria-label="Next slide"
                  onClick={handleNextClick}
                ></i>
              </div> */}
            </div>
          </div>
        </section>
      ) : (
        <>
          <section class="sectionContainer">
            <div
              class="responsivePadding"
              style={{ maxWidth: "1310px", width: "100%", paddingRight: 0 }}
            >
              <div class="c-carousel swiper swiper-initialized swiper-horizontal swiper-android swiper-backface-hidden">
                <div class="c-carousel__header">
                  <h2 class="c-carousel__headerHeading">Who we empowered</h2>
                  {/* <div class="c-carousel__navigation c-carousel__navigation--desktop">
                    <i
                      class="fa-solid fa-angle-left c-carousel__navigationButton c-carousel__navigationButton--left swiper-button-disabled swiper-button-lock"
                      tabindex="-1"
                      role="button"
                      aria-label="Previous slide"
                      aria-controls="swiper-wrapper-bd43da7f24c1074f6"
                      aria-disabled="true"
                    ></i>
                    <i
                      class="fa-solid fa-angle-right c-carousel__navigationButton c-carousel__navigationButton--right swiper-button-disabled swiper-button-lock"
                      tabindex="-1"
                      role="button"
                      aria-label="Next slide"
                      aria-controls="swiper-wrapper-bd43da7f24c1074f6"
                      aria-disabled="true"
                    ></i>
                  </div> */}
                </div>
                <div
                  class="c-carousel__testimonials swiper-wrapper"
                  id="swiper-wrapper-bd43da7f24c1074f6"
                  aria-live="off"
                >
                  <div
                    class="c-carousel__testimonial swiper-slide swiper-slide-active"
                    style={{ width: "384px", marginRight: "30px" }}
                    role="group"
                    aria-label="1 / 3"
                  >
                    <div class="c-carousel__testimonialTitle">
                      Improved access for all students{" "}
                    </div>
                    <div class="c-carousel__testimonialMessage">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut mauris ligula, convallis quis est a, lobortis
                        pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                        pretium, orci felis rutrum enim, nec placerat metus odio
                        sit amet velit. Integer ac porttitor tortor. Donec
                        sollicitudin, nisi a rhoncus consectetur, tortor est
                        efficitur nibh, volutpat.
                      </p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <button class="c-carousel__testimonialButton">
                        Read more
                      </button>
                    </div>
                    <div class="c-carousel__testimonialAuthor">
                      Head of English, Greenwich International School{" "}
                    </div>
                  </div>
                  <div
                    class="c-carousel__testimonial swiper-slide swiper-slide-next"
                    style={{ width: "384px", marginRight: "30px" }}
                    role="group"
                    aria-label="2 / 3"
                  >
                    <div class="c-carousel__testimonialTitle">
                      Improved access for all students 3{" "}
                    </div>
                    <div class="c-carousel__testimonialMessage">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut mauris ligula, convallis quis est a, lobortis
                        pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                        pretium, orci felis rutrum enim, nec placerat metus odio
                        sit amet velit. Integer ac porttitor tortor. Donec
                        sollicitudin, nisi a rhoncus consectetur, tortor est
                        efficitur nibh, volutpat.
                      </p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <button class="c-carousel__testimonialButton">
                        Read more
                      </button>
                    </div>
                    <div class="c-carousel__testimonialAuthor">
                      Head of English, Greenwich International School{" "}
                    </div>
                  </div>
                  <div
                    class="c-carousel__testimonial swiper-slide"
                    role="group"
                    aria-label="3 / 3"
                    style={{ width: "384px", marginRight: "30px" }}
                  >
                    <div class="c-carousel__testimonialTitle">
                      Improved access for all students 2{" "}
                    </div>
                    <div class="c-carousel__testimonialMessage">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut mauris ligula, convallis quis est a, lobortis
                        pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                        pretium, orci felis rutrum enim, nec placerat metus odio
                        sit amet velit. Integer ac porttitor tortor. Donec
                        sollicitudin, nisi a rhoncus consectetur, tortor est
                        efficitur nibh, volutpat.
                      </p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <button class="c-carousel__testimonialButton">
                        Read more
                      </button>
                    </div>
                    <div class="c-carousel__testimonialAuthor">
                      Head of English, Greenwich International School{" "}
                    </div>
                  </div>
                  <div
                    class="c-carousel__testimonial swiper-slide"
                    role="group"
                    aria-label="3 / 3"
                    style={{ width: "384px", marginRight: "30px" }}
                  >
                    <div class="c-carousel__testimonialTitle">
                      Improved access for all students 2{" "}
                    </div>
                    <div class="c-carousel__testimonialMessage">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut mauris ligula, convallis quis est a, lobortis
                        pellentesque lectus. Nunc sollicitudin, ex sed iaculis
                        pretium, orci felis rutrum enim, nec placerat metus odio
                        sit amet velit. Integer ac porttitor tortor. Donec
                        sollicitudin, nisi a rhoncus consectetur, tortor est
                        efficitur nibh, volutpat.
                      </p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <button class="c-carousel__testimonialButton">
                        Read more
                      </button>
                    </div>
                    <div class="c-carousel__testimonialAuthor">
                      Head of English, Greenwich International School{" "}
                    </div>
                  </div>
                </div>
                {/* <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock">
                  <div
                    class="c-carousel__testimonialNavigatorBar swiper-pagination-bullet swiper-pagination-bullet-active"
                    tabindex="0"
                    aria-current="true"
                  ></div>
                </div>
                <div class="c-carousel__navigation c-carousel__navigation--mobile">
                  <i class="fa-solid fa-angle-left c-carousel__navigationButton c-carousel__navigationButton--prev"></i>
                  <i class="fa-solid fa-angle-right c-carousel__navigationButton c-carousel__navigationButton--next"></i>
                </div>
                <span
                  class="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span> */}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Box;
